import React from 'react'
import PropTypes from 'prop-types'

import './styles.scss'

const NumberItem = ({ number, children }) => (
  <div className="data-shared__number-item">
    <div className="number-item__number">{number}</div>
    <p className="number-item__text">{children}</p>
  </div>
)
NumberItem.propTypes = {
  number: PropTypes.number.isRequired,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
}

export default NumberItem
