import React from 'react'
import useWindowSize from 'hooks/useWindowSize'
import CookiesComputer from 'assets/svg/cookies-computador.svg'
import IllustrationShopper from 'assets/svg/bag-alerta.svg'
import PublicityTimeline from 'assets/svg/timeline-text-communication.svg'
import PublicityTimelineMobile from 'assets/svg/timeline-text-communication-mobile.svg'
import IllustrationGlass from 'assets/svg/lupa-telefone.svg'
import GatsbyImage from 'gatsby-image'
import { graphql, useStaticQuery } from 'gatsby'
import SectionTitle from '../../components/SectionTitle'

import './styles.scss'
import SubSectionTitle from '../../components/SubSectionTitle'
import InfoBlock from '../../components/InfoBlock'

const CookiesInfoCouriers = () => {
  const { isMobileDevice } = useWindowSize()
  const { thirdPartyTechnologies } = useStaticQuery(graphql`
    query {
      thirdPartyTechnologies: file(relativePath: { eq: "privacy/ifood-ilustra-techteam.png" }) {
        childImageSharp {
          fluid(maxWidth: 1400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <section className="user-cookies-couriers" id="capitulo-9">
      <header>
        <div className="container">
          <div className="columns is-vcentered">
            <div className="column">
              <SectionTitle>9. Como utilizamos cookies e outras tecnologias?</SectionTitle>
              <p>
                O iFood pode utilizar, para uma variedade de funções, tecnologias como cookies,
                cache, identificadores da sessão apenas para dispositivos móveis e/ou tecnologias
                semelhantes. Essas tecnologias nos ajudam a{' '}
                <strong>
                  autenticar a sua conta, promover e aperfeiçoar os nossos serviços, personalizar a
                  sua experiência e avaliar a eficácia da nossa comunicação.
                </strong>
              </p>
            </div>
            <div className="column is-flex is-justify-content-center">
              <CookiesComputer />
            </div>
          </div>
        </div>
      </header>
      <div className="user-cookies-couriers__technologies">
        <div className="container">
          <div className="column">
            <SubSectionTitle>9.1. O que são essas tecnologias?</SubSectionTitle>
          </div>
          <div className="user-cookies-couriers__technologies--cards">
            <InfoBlock className="infoBlock-card">
              <p className="text-primary font-bold">CACHE</p>
              <p>
                armazena dados ou processos utilizados no para permitir um futuro acesso mais rápido
                e eficiente.
              </p>
            </InfoBlock>
            <InfoBlock className="infoBlock-card">
              <p className="text-primary font-bold">IDENTIFICADORES DA SESSÃO</p>
              <p>identificam quem está conectado(a) ao sistema.</p>
            </InfoBlock>
            <InfoBlock className="infoBlock-card">
              <p className="text-primary font-bold">COOKIES</p>
              <p>
                são pequenos arquivos armazenados no seu navegador, celular ou outro dispositivo.
              </p>
            </InfoBlock>
          </div>
        </div>
      </div>
      <div className="container user-cookies-couriers__communication">
        <div className="columns">
          <div className="column is-two-fifths">
            <IllustrationShopper />
          </div>
          <div className="column">
            <SubSectionTitle>9.2. Para oferecer uma experiência personalizada</SubSectionTitle>
            <p>
              O iFood e empresas parceiras que nos prestam serviços também usam cookies e outras
              tecnologias para memorizar informações pessoais quando você usa a Plataforma ou a rede
              de parceiros do iFood que possam usar tecnologias similares. A nossa meta, nesses
              casos, é fazer com que a sua experiência com o iFood seja mais conveniente e
              personalizada.
            </p>
            <InfoBlock className="block-text-communication">
              <p>
                Por exemplo, saber o seu primeiro nome nos permite dar boas-vindas a você na próxima
                vez em que você acessar o iFood ou indicar um ponto de retirada de bicicletas
                elétricas para você alugar.
              </p>
            </InfoBlock>
          </div>
        </div>
      </div>
      <div className="user-cookies-couriers__publicity">
        <div className="user-cookies-couriers__publicity--text">
          <div>
            <SubSectionTitle>
              9.3. Para avaliar a eficácia da nossa comunicação e publicidade
            </SubSectionTitle>
            <p>Também usamos essas informações para:</p>
          </div>
        </div>
        <div className="user-cookies-couriers__publicity--illustration">
          {isMobileDevice ? <PublicityTimelineMobile /> : <PublicityTimeline />}
          <IllustrationGlass />
        </div>
      </div>
      <div className="container user-cookies-couriers__third">
        <div className="columns is-vcentered">
          <div className="column">
            <SubSectionTitle>9.4. Tecnologia de terceiros</SubSectionTitle>
            <p>
              Além dos cookies e outras tecnologias do iFood, nós podemos permitir que terceiros
              contratados pelo iFood utilizem cookies e outras tecnologias de sua propriedade para
              identificar seu navegador e dispositivo, de modo a lhe oferecer facilidades como:{' '}
              <strong>iFood Delivery de Vantagens e iFood Pedal.</strong>
            </p>
          </div>
          <div className="column third-party-couriers__illustration">
            <GatsbyImage
              alt="Ilustração com pessoa com telefone na mão"
              className="illustration__img"
              fluid={thirdPartyTechnologies.childImageSharp.fluid}
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default CookiesInfoCouriers
