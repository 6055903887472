import React from 'react'
import GatsbyImage from 'gatsby-image/index'
import useWindowSize from 'hooks/useWindowSize'
import useQueryPrivacyCouriers from 'graphql/useQueryPrivacyCouriers'
import SectionTitle from '../../components/SectionTitle'
import SubSectionTitle from '../../components/SubSectionTitle'

import './styles.scss'

const ExerciseUserRightsCouriers = () => {
  const { isMobileDevice } = useWindowSize()
  const {
    active,
    register,
    desactive,
    mobile1,
    mobile2,
    mobile3,
    mobile4,
    mobile5,
    mobile6,
    mobile7,
    mobile8,
    mobile9,
    mobile10,
  } = useQueryPrivacyCouriers()

  return (
    <section className="exercise-rights-couriers" id="capitulo-12">
      <div className="container">
        <SectionTitle>12. Como exercer os seus direitos?</SectionTitle>
        <div className="column exercise-rights-couriers__text-column">
          <p>
            Para que você exerça seus direitos enquanto titular de dados pessoais, o iFood
            disponibiliza meios específicos para as solicitações na Plataforma. Veja abaixo:
          </p>
          <SubSectionTitle>12.1 Atendimento</SubSectionTitle>
          <p>
            Se você quiser fazer uma solicitação ou tiver alguma dúvida sobre o tratamento
            de dados pessoais, você poderá entrar em contato conosco pela Plataforma, na
            aba “Suporte”.
          </p>
          <SubSectionTitle>12.2 Outros diretos</SubSectionTitle>
        </div>
      </div>
      {isMobileDevice ? (
        <div className="exercise-rights-couriers__screen-print">
          <p>a) Cadastro</p>
          <GatsbyImage
            alt="Página Inicial"
            fluid={mobile1.childImageSharp.fluid}
            className="image-step"
          />
          <GatsbyImage
            alt="Aba “Gerenciar Minha Conta”"
            fluid={mobile2.childImageSharp.fluid}
            className="image-step"
          />
          <GatsbyImage
            alt="Aba “Gerenciar Minha Conta”"
            fluid={mobile3.childImageSharp.fluid}
            className="image-step"
          />
          <p>b) Entregador(a) ativo(a)</p>
          <GatsbyImage
            alt="Página Perfil"
            fluid={mobile4.childImageSharp.fluid}
            className="image-step"
          />
          <GatsbyImage
            alt="Página Perfil"
            fluid={mobile5.childImageSharp.fluid}
            className="image-step"
          />
          <GatsbyImage
            alt="Aba “Gerenciar Minha Conta”"
            fluid={mobile6.childImageSharp.fluid}
            className="image-step"
          />
          <GatsbyImage
            alt="Aba “Gerenciar Minha Conta”"
            fluid={mobile7.childImageSharp.fluid}
            className="image-step"
          />
          <p>c) Entregador(a) desativado(a)</p>
          <GatsbyImage
            alt="Tela de Desativação"
            fluid={mobile8.childImageSharp.fluid}
            className="image-step"
          />
          <GatsbyImage
            alt="Aba “Gerenciar Minha Conta”"
            fluid={mobile9.childImageSharp.fluid}
            className="image-step"
          />
          <GatsbyImage
            alt="Aba “Gerenciar Minha Conta”"
            fluid={mobile10.childImageSharp.fluid}
            className="image-step"
          />
        </div>
      ) : (
        <div className="exercise-rights-couriers__screen-print">
          <p>a) Cadastro</p>
          <GatsbyImage alt="Tela cadastro" fluid={register.childImageSharp.fluid} />
          <p>b) Entregador(a) ativo(a)</p>
          <GatsbyImage alt="Tela entregador ativo" fluid={active.childImageSharp.fluid} />
          <p>c) Entregador(a) desativado(a)</p>
          <GatsbyImage alt="Entregador desativado" fluid={desactive.childImageSharp.fluid} />
        </div>
      )}
    </section>
  )
}

export default ExerciseUserRightsCouriers
