import React from 'react'

import withHtmlContent from 'utils/withHtmlContent'

import DesktopIllustration from 'assets/svg/couriers-privacy-1-desktop.svg'
import MobileIllustration from 'assets/svg/couriers-privacy-1-mobile.svg'

import SectionTitle from '../../components/SectionTitle'

import { data } from './helpers'

import './styles.scss'

const WhoThisDeclarationApplyCouriers = () => {
  const { title, description, audiences, definitions } = data
  return (
    <section id="capitulo-1" className="couriers-privacy-scope">
      <div className="couriers-privacy-scope__wrapper">
        {/* Section header */}
        <div className="couriers-privacy-scope__content-heading columns">
          <div className="couriers-privacy-scope__content-left column is-5">
            {/* Section title */}
            <SectionTitle className="couriers-privacy-scope__title">{title}</SectionTitle>
            {/* Section description */}
            <p
              className="couriers-privacy-scope__description text-xl font-normal"
              {...withHtmlContent(description)}
            />
          </div>
        </div>

        {/* Section illustration */}
        <div className="couriers-privacy-scope__desktop-illustration">
          <DesktopIllustration />
        </div>

        {/* Section illustration */}
        <div className="couriers-privacy-scope__mobile-illustration">
          <MobileIllustration />
        </div>

        {/* Section content */}
        <div className="couriers-privacy-scope__content">
          {/* Section audiences */}
          <div className="couriers-privacy-scope__audiences">
            {audiences.map((audience, index) => (
              /* Section audience */
              <div
                key={audience.uid}
                className={`couriers-privacy-scope__audience audience--0${index + 1}`}
              >
                {/* Section audience text */}
                <span {...withHtmlContent(audience.content)} />
              </div>
            ))}
          </div>

          {/* Section definitions */}
          <div className="couriers-privacy-scope__definitions">
            {definitions.map((definition, index) => (
              /* Section definition */
              <div
                key={definition.uid}
                className={`couriers-privacy-scope__definition definition--0${index + 1}`}
              >
                {/* Section definition text */}
                <span {...withHtmlContent(definition.content)} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

export default WhoThisDeclarationApplyCouriers
