import React from 'react'
import IllustrationPhone from 'assets/svg/phone-illustration.svg'
import ExclamationMark from 'assets/svg/illustration-exclamacao.svg'

import HandsWithBagsAndCoin from 'assets/svg/couriers-privacy-chapter-5-arms-bags-and-coin.svg'
import HandWithPhone from 'assets/svg/couriers-privacy-chapter-5-2-hand-with-phone.svg'

import InfoBoxesWithDotsMobile from 'assets/svg/couriers-privacy-chapter-5-1-info-boxes-mobile.svg'
import InfoBoxesWithDots from 'assets/svg/couriers-privacy-chapter-5-1-info-boxes.svg'
import Payment from 'assets/svg/couriers-privacy-chapter-5-4-payment.svg'
import Security from 'assets/svg/couriers-privacy-chapter-5-5-security.svg'
import Promotions from 'assets/svg/couriers-privacy-chapter-5-6-promotions.svg'
import Research from 'assets/svg/couriers-privacy-chapter-5-7-research.svg'
import Legal from 'assets/svg/couriers-privacy-chapter-5-8-legal.svg'

import useWindowSize from 'hooks/useWindowSize'
import SectionTitle from '../../components/SectionTitle'
import SubSectionTitle from '../../components/SubSectionTitle'
import InfoBlock from '../../components/InfoBlock'
import CardText from '../../components/CardText'

import './styles.scss'

const WhyCollectYourDataCouriers = () => {
  const { isMobileDevice } = useWindowSize()
  return (
    <section id="capitulo-5" className="why-collect-your-data-couriers">
      <div className="why-collect-your-data-couriers__subsection-white">
        <div className="container">
          <div className="columns subsection-white-chapter-five">
            <div className="column column">
              <SectionTitle>5. Por que coletamos os seus dados?</SectionTitle>
              <p className="text-xl text-grey-2 font-normal">
                Saiba quais são as finalidades de tratamento dos seus dados pessoais relacionadas
                aos serviços da Plataforma.
              </p>
              <SubSectionTitle>
                5.1. Para conectar você a Estabelecimentos e Clientes
              </SubSectionTitle>
              <p className="text-xl text-grey-2 font-normal">
                Podemos utilizar os dados coletados para{' '}
                <strong>prover, manter, melhorar e personalizar nossa Plataforma</strong> aos
                Entregadores e Entregadoras, por meio de atividades existentes ou a serem criadas.
              </p>
              <p className="text-xl text-grey-2 font-normal">
                Também poderemos usar suas informações pessoais para fins internos, tais como:
              </p>
            </div>
            {!isMobileDevice && (
              <div className="column column is-flex is-justify-content-center">
                <HandsWithBagsAndCoin />
              </div>
            )}
          </div>
          <div className="column subsection-five-one-info-boxes is-flex is-justify-content-center">
            {isMobileDevice ? <InfoBoxesWithDotsMobile /> : <InfoBoxesWithDots />}
          </div>
        </div>
        <div className="container">
          <div className="columns subsection-white-chapter-five-two">
            <div className="column column-info is-flex is-justify-content-center">
              <SubSectionTitle>5.2. Para te enviar comunicações importantes</SubSectionTitle>
              <p className="text-xl text-grey-2 font-normal">
              Periodicamente, poderemos usar seus dados pessoais para enviar
              avisos e notificações importantes, como comunicados sobre entregas
              de pedidos, alterações em prazos, condições, políticas e campanhas
              de comunicação.{' '}
              </p>
              {isMobileDevice ? (
                <CardText
                  text="Como essas informações são importantes para a sua interação com
                o iFood, você não poderá optar por não receber esse tipo de
                comunicação, já que são necessárias e indispensáveis para a
                atividade de entrega."
                />
              ) : (
                <p className="text-xl text-grey font-normal subsection-with-purple-arm">
                  Como essas informações são importantes para a sua interação com o iFood,
                  você não poderá optar por não receber esse tipo de comunicação, já que
                  são necessárias e indispensáveis para a atividade de entrega.{' '}
                  <HandWithPhone />
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="why-collect-your-data-couriers__subsection-grey">
        <div className="container">
          <div className="column subsection-grey-chapter-five-three">
            <SubSectionTitle>5.3. Para oferecer suporte</SubSectionTitle>
            <p className="text-xl text-grey-2 font-normal">
              Também teremos acesso às suas informações quando você fizer solicitações, tirar
              dúvidas e solicitar informações por meio da aba “Suporte” na Plataforma.
            </p>
            <p className="text-xl text-black font-bold subsection-with-purple-arm">
              Neste caso, poderemos utilizar seus dados cadastrais para:
            </p>
            <div className="subsection-grey-chapter-five-three__cards-list">
              <CardText
                className="text-label"
                text="confirmar que a solicitação foi feita por você."
              />
              <CardText
                className="text-label"
                text="direcionar as suas dúvidas para o atendente de suporte."
              />
              <CardText className="text-label" text="investigar e direcionar seu problema." />
              <CardText
                className="text-label"
                text="monitorar e melhorar nossos procedimentos de suporte."
              />
            </div>
          </div>
        </div>
      </div>
      <div className="why-collect-your-data-couriers__subsection-white">
        <div className="container">
          <div className="columns subsection-white-chapter-five-four">
            <div className="column">
              <SubSectionTitle>5.4. Para efetuar o seu repasse</SubSectionTitle>
              <p className="text-xl text-grey-2 font-normal">
                Ao repassar o valor das entregas feitas por você, o iFood coleta os seus dados
                bancários e os compartilha com empresas processadoras e instituições bancárias.
              </p>
              <InfoBlock className="text-block-chapter-five-four">
                <p className="text-white">
                  Por meio de conciliação bancária, a empresa processadora verifica as transações e
                  encaminha para o banco efetuar os pagamentos.
                </p>
                <p className="text-white">
                  Ambos têm acesso restrito às informações apenas para executar atividades
                  solicitadas pelo iFood e não podem usar ou divulgar tais informações para nenhuma
                  outra finalidade.
                </p>
              </InfoBlock>
            </div>
            <div className="column is-flex is-align-items-center is-justify-content-center">
              <Payment />
            </div>
          </div>
          <div className="columns subsection-white-chapter-five-five">
            {!isMobileDevice && (
              <div className="column is-flex is-align-items-center is-justify-content-center">
                <Security />
              </div>
            )}
            <div className="column">
              <SubSectionTitle>5.5. Para segurança e prevenção à fraude</SubSectionTitle>
              <InfoBlock>
                <ExclamationMark />
                <p>
                  Pensando na segurança de nossos Clientes Finais, analisamos diversos elementos
                  fornecidos no momento do cadastro pelo Entregador ou Entregadora, bem como
                  informações publicamente disponíveis, para avaliar a liberação do seu cadastro na
                  Plataforma, dentro dos limites permitidos por lei.
                </p>
              </InfoBlock>
              <p className="text-xl text-grey-2 font-normal">
                Podemos analisar elementos que confirmem a sua identidade e condições obrigatórias ao exercício da atividade de entrega, tais como: {' '}
                <strong>
                  localização, validade da carteira de habilitação, compatibilidade da habilitação com a foto, dentre outros.
                </strong>
              </p>
            </div>
            {isMobileDevice && (
              <div className="column is-flex is-align-items-center is-justify-content-center">
                <Security />
              </div>
            )}
          </div>
          <div className="columns subsection-white-chapter-five-six">
            <div className="column">
              <SubSectionTitle>5.6. Para personalizar as promoções</SubSectionTitle>
              <p className="text-xl text-grey-2 font-normal">
                Ao se cadastrar na{' '}
                <a
                  href="https://shop.ifood.com.br/loja-do-entregador/1969/c"
                  className="text-primary"
                >
                  <span className="text-primary font-bold">
                    Loja do Entregador
                  </span>
                </a>
                , mandaremos para você promoções personalizadas, o que inclui o envio de mensagens
                por SMS e WhatsApp.
              </p>
              <InfoBlock className="five-seven-left-text-block">
                {!isMobileDevice && <IllustrationPhone />}
                <p className="text-grey-2 text-sm">
                  <strong>
                    Nesse caso, você pode optar por não mais receber as promoções
                    alterando suas preferências diretamente na loja.
                  </strong>
                </p>
              </InfoBlock>
              <p className="text-xl text-grey-2 font-normal">
                Você também pode participar de diversas promoções que fazem parte de
                programas desenvolvidos em conjunto com nossos parceiros de negócio, tais
                como eventos, cursos, descontos, ofertas e outras parcerias. Nestas
                hipóteses, sua participação será opcional, sempre podendo escolher não
                participar dos programas disponibilizados.{' '}
              </p>
              <p className="text-xl text-grey-2 font-normal mt-0">
                Se você participar de um concurso, competição ou qualquer outra
                modalidade de promoção, poderemos usar as informações que você fornecer
                para administrar esses programas.{' '}
              </p>
            </div>
            <div className="column is-flex is-align-items-center is-justify-content-center">
              <Promotions />
            </div>
          </div>
          <div className="columns subsection-white-chapter-five-seven">
            {!isMobileDevice && (
              <div className="column is-flex is-align-items-flex-start is-justify-content-center">
                <Research />
              </div>
            )}
            <div className="column column">
              <SubSectionTitle>5.7. Para promover pesquisas</SubSectionTitle>
              <p className="text-xl text-grey-2 font-normal">
                Utilizamos seus dados para entrar em contato diretamente com você e coletar a sua opinião em pesquisas para aprimorar nossa Plataforma. Nessa hipótese, perguntaremos se você concorda ou não em participar, podendo sempre recusar.
              </p>
              <p className="text-xl text-grey-2 font-normal">
                Também podemos cooperar com pesquisas e estudos acadêmicos independentes, realizados por docentes, estudantes e pesquisadores de órgãos de pesquisa ou de instituições de ensino.
              </p>
            </div>
            {isMobileDevice && (
              <div className="column column is-flex is-align-items-flex-start is-justify-content-center">
                <Research />
              </div>
            )}
          </div>
          <div className="columns subsection-white-chapter-five-eight">
            <div className="column column-left">
              <SubSectionTitle>5.8. Em caso de requisições legais e regulatórias</SubSectionTitle>
              <p className="text-xl text-grey-2 font-normal">
                Seus dados poderão ser utilizados para endereçarmos reivindicações jurídicas e
                regulatórias relacionadas à utilização da nossa Plataforma.
              </p>
            </div>
            <div className="column column-right is-flex is-align-items-center is-justify-content-center">
              <Legal />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default WhyCollectYourDataCouriers
