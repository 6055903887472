import { graphql, useStaticQuery } from 'gatsby'

export default function useQueryPrivacyCouriers() {
  return useStaticQuery(graphql`
    query {
      active: file(relativePath: { eq: "privacy-couriers/active-chapter-12.png" }) {
        childImageSharp {
          fluid(maxWidth: 1600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      register: file(relativePath: { eq: "privacy-couriers/register-chapter-12.png" }) {
        childImageSharp {
          fluid(maxWidth: 1600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      desactive: file(relativePath: { eq: "privacy-couriers/desactive-chapter-12.png" }) {
        childImageSharp {
          fluid(maxWidth: 1600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mobile1: file(relativePath: { eq: "privacy-couriers/mobile-chapter-12-1.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mobile2: file(relativePath: { eq: "privacy-couriers/mobile-chapter-12-2.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mobile3: file(relativePath: { eq: "privacy-couriers/mobile-chapter-12-3.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mobile4: file(relativePath: { eq: "privacy-couriers/mobile-chapter-12-4.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mobile5: file(relativePath: { eq: "privacy-couriers/mobile-chapter-12-5.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mobile6: file(relativePath: { eq: "privacy-couriers/mobile-chapter-12-6.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mobile7: file(relativePath: { eq: "privacy-couriers/mobile-chapter-12-7.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mobile8: file(relativePath: { eq: "privacy-couriers/mobile-chapter-12-8.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mobile9: file(relativePath: { eq: "privacy-couriers/mobile-chapter-12-9.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mobile10: file(relativePath: { eq: "privacy-couriers/mobile-chapter-12-10.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
}
