import React from 'react'
import IllustrationBook from 'assets/svg/declaracao-privacidade.svg'
import ExclamationMark from 'assets/svg/illustration-exclamacao.svg'
import InfoBlock from '../../components/InfoBlock'
import SectionTitle from '../../components/SectionTitle'

import './styles.scss'

const NotifyingDeclarationChangesCouriers = () => (
  <section className="declaration-changes-couriers" id="capitulo-13">
    <div className="container">
      <div className="columns is-vcentered">
        <div className="column is-three-fifths">
          <SectionTitle>13. Como notificamos as mudanças feitas nesta Declaração?</SectionTitle>
          <p className="text-grey-2">
            O iFood poderá atualizar essa Declaração de Privacidade periodicamente, sendo que a
            versão em vigor será sempre <strong>a mais recente.</strong>
          </p>
          <InfoBlock>
            <ExclamationMark />
            <p>
              Se fizermos alguma alteração na Declaração em termos materiais, colocaremos um aviso
              na Plataforma ou enviaremos um e-mail, juntamente com a Declaração Privacidade
              atualizada.
            </p>
          </InfoBlock>
          <p className="text-grey-2">
            Para verificar a data da versão em vigor, verifique a “Data de atualização” no início
            deste documento.
          </p>
        </div>
        <div className="column">
          <IllustrationBook />
        </div>
      </div>
      <p>Agradecemos a leitura da nossa Declaração de Privacidade!</p>
    </div>
  </section>
)

export default NotifyingDeclarationChangesCouriers
