import React from 'react'
import ExclamationMark from 'assets/svg/illustration-exclamacao.svg'
import Illustration from 'assets/svg/ilustra-capitulo-3.svg'
import InfoBlock from '../../components/InfoBlock'
import SectionTitle from '../../components/SectionTitle'
import './styles.scss'

const WhoControlsTreatmentCouriers = () => (
    <section id="capitulo-3" className="who-controls-treatment-couriers">
      <div className="container">
        <div className="columns">
          <div className="column who-controls-treatment-couriers__column-left">
            <SectionTitle>3. Quem controla o tratamento?</SectionTitle>
            <p className="text-2xl text-grey-2">
              Quando você se cadastra e/ou acessa a nossa Plataforma, o iFood exerce o papel de
              controlador do tratamento de seus dados pessoais, atuando conforme a legislação
              aplicável e conforme descrito na presente Declaração.
            </p>
            <InfoBlock className="who-controls-treatment-couriers__column-left--block">
              <ExclamationMark />
              <p>
                O controlador é a pessoa física ou jurídica que decide como será realizado o
                tratamento de dados pessoais.
              </p>
            </InfoBlock>
          </div>
          <div className="column who-controls-treatment-couriers__column-right">
            <Illustration />
          </div>
        </div>
      </div>
    </section>
  )

export default WhoControlsTreatmentCouriers
