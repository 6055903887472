import React from 'react'
// import { navigate } from 'gatsby';
import SEO from 'components/SEO'
import Layout from 'components/Layout'
import HeroBanner from 'components/Sections/Privacy/components/HeroBanner'
import ProgressBar from 'components/ProgressBar'
import ChooseChapter from 'components/Sections/Privacy/ChooseChapter'
import Chapters from 'components/Sections/Privacy/components/Chapters'
import { data } from 'components/Sections/Privacy/PrivacyCouriers/helpers'
import WhoThisDeclarationApplyCouriers from 'components/Sections/Privacy/PrivacyCouriers/WhoThisDeclarationApplyCouriers'
import ProcessingPersonalDataCouriers from 'components/Sections/Privacy/PrivacyCouriers/ProcessingPersonalDataCouriers'
import WhoControlsTreatmentCouriers from 'components/Sections/Privacy/PrivacyCouriers/WhoControlsTreatmentCouriers'
import WhatDataIsCollectedCouriers from 'components/Sections/Privacy/PrivacyCouriers/WhatDataIsCollectedCouriers'
import WhyCollectYourDataCouriers from 'components/Sections/Privacy/PrivacyCouriers/WhyCollectYourDataCouriers'
import ProtectingYourDataCouriers from 'components/Sections/Privacy/PrivacyCouriers/ProtectingYourDataCouriers'
import HowDataIsStoredCouriers from 'components/Sections/Privacy/PrivacyCouriers/HowDataIsStoredCouriers'
import HowDataIsSharedCouriers from 'components/Sections/Privacy/PrivacyCouriers/HowDataIsSharedCouriers'
import CookiesInfoCouriers from 'components/Sections/Privacy/PrivacyCouriers/CookiesInfoCouriers'
import DataTransferToOtherCountriesCouriers from 'components/Sections/Privacy/PrivacyCouriers/DataTransferToOtherCountriesCouriers'
import UserRightsCouriers from 'components/Sections/Privacy/PrivacyCouriers/UserRightsCouriers'
import ExerciseUserRightsCouriers from 'components/Sections/Privacy/PrivacyCouriers/ExerciseUserRightsCouriers'
import NotifyingDeclarationChangesCouriers from 'components/Sections/Privacy/PrivacyCouriers/NotifyingDeclarationChangesCouriers'
import IntroductionSectionCouriers from 'components/Sections/Privacy/PrivacyCouriers/IntroductionSectionCouriers'

const PrivacyCouriers = () => {
  // React.useEffect(() => {
  //   navigate('/privacidade/');
  // }, []);

  // return null;

  return (
    <Layout>
      <SEO description="Conheça nossa Política de privacidade" title="Privacidade" />
      <HeroBanner className="couriers-privacy__hero-banner" data={data} />
      <ProgressBar />
      <IntroductionSectionCouriers />
      <ChooseChapter data={data} />
      <Chapters>
        <WhoThisDeclarationApplyCouriers />
        <ProcessingPersonalDataCouriers />
        <WhoControlsTreatmentCouriers />
        <WhatDataIsCollectedCouriers />
        <WhyCollectYourDataCouriers />
        <ProtectingYourDataCouriers />
        <HowDataIsStoredCouriers />
        <HowDataIsSharedCouriers />
        <CookiesInfoCouriers />
        <DataTransferToOtherCountriesCouriers />
        <UserRightsCouriers />
        <ExerciseUserRightsCouriers />
        <NotifyingDeclarationChangesCouriers />
      </Chapters>
    </Layout>
  );
}

export default PrivacyCouriers
