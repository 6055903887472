import React from 'react'
import DataShareIllustration from 'assets/svg/illustration-shared-data.svg'
import DataShareTextPath from 'assets/svg/couriers-privacy-chapter-8-service-providers.svg'
import Authorities from 'assets/svg/autoridades.svg'
import LawAnalysis from 'assets/svg/analise-lei.svg'
import useWindowSize from 'hooks/useWindowSize'

import SectionTitle from '../../components/SectionTitle'
import SubSectionTitle from '../../components/SubSectionTitle'
import InfoBlock from '../../components/InfoBlock'
import NumberItem from './NumberItem'

import './styles.scss'

const HowDataIsSharedCouriers = () => {
  const { isMobileDevice } = useWindowSize()

  return (
    <section className="data-shared-couriers py-16" id="capitulo-8">
      <div className="container">
        <div className="columns">
          <div className="column is-half">
            <div className="data-shared__title mb-16">
              <SectionTitle>8. Como os dados são compartilhados?</SectionTitle>
              <p className="text-grey-2 text-xl font-normal mt-2">
                Saiba com quem o iFood pode compartilhar os seus dados.
              </p>
              {isMobileDevice ? <DataShareIllustration /> : null}
            </div>
            <div className="data-shared__summary">
              <SubSectionTitle>8.1. Provedores de serviços </SubSectionTitle>
              <p>
                Por razões de processamento de dados em nome do iFood, poderemos compartilhar dados
                com terceiros prestadores de serviços. Nesse caso, os dados serão tratados de forma
                a <strong>proteger a sua privacidade,</strong> tendo essas empresas o dever
                contratual de garantir proteção compatível com a legislação aplicável e com os
                termos desta Declaração de Privacidade do iFood.
              </p>
              <p>
                Nós podemos utilizar os serviços dessas empresas para facilitar nosso serviço,
                prover o serviço do iFood em nosso nome e executar atividades relacionadas ao
                serviço, incluindo:
              </p>
            </div>
          </div>
          <div className="column is-half">{!isMobileDevice ? <DataShareIllustration /> : null}</div>
        </div>
      </div>
      <div className="container">
        <div className="columns data-shared__illustrations is-vcentered">
          <div className="column is-two-fifths">
            <DataShareTextPath />
          </div>
        </div>
      </div>
      <InfoBlock className="data-shared__info-block--big">
        <p className="text-grey-1">
          Essas empresas ou indivíduos têm acesso{' '}
          <strong>restrito ao necessário aos seus dados pessoais</strong>, nos limites da execução
          das atividades solicitadas pelo iFood. Elas também são obrigadas a não usar ou divulgar
          tais informações para nenhuma outra finalidade.
        </p>
      </InfoBlock>
      <div className="container data-shared__partners">
        <div className="columns">
          <div className="column">
            <SubSectionTitle>8.2 Clientes Finais</SubSectionTitle>
            <p>
              Quando você estiver realizando as atividades de entrega, os Clientes Finais poderão
              ver seu <strong>nome e foto para fins de segurança,</strong> bem como a sua{' '}
              <strong>localização</strong> para acompanhamento do pedido em trânsito.
            </p>
            <p>
              Caso você inicie um chat com o Cliente Final durante a entrega, compartilharemos os
              mesmos dados citados acima, além do conteúdo da mensagem escrita por você.
            </p>
          </div>
          <div className="column">
            <SubSectionTitle>8.3. Estabelecimentos Parceiros do iFood</SubSectionTitle>
            <p>
              Ao realizar as atividades de entrega, poderemos compartilhar suas informações para
              fins de <strong>segurança e identificação no ato da coleta</strong> do pedido a ser
              entregue, como seu <strong>nome e foto.</strong>
            </p>
          </div>
        </div>
        <div className="columns">
          <div className="column">
            <SubSectionTitle>8.4. Parceiros comerciais</SubSectionTitle>
            <p>
              Podemos compartilhar seus dados com empresas com as quais o iFood mantém um
              relacionamento de colaboração ou parceria, a fim de realizar atividades financeiras ou
              comerciais que envolvem oferecer <strong>promoções, produtos e serviços</strong>{' '}
              dessas empresas em conjunto com o iFood.
            </p>
            <InfoBlock>
              <p>
                Nesses casos, são firmados acordos ou contratos com o objetivo de{' '}
                <strong>proteger a privacidade e os dados pessoais</strong> de usuários do iFood e o
                cumprimento da legislação aplicável para que todas as medidas cabíveis para{' '}
                <strong>garantir o cumprimento dos padrões de confidencialidade e segurança</strong>{' '}
                sejam tomadas.
              </p>
            </InfoBlock>
          </div>
          <div className="column">
            <SubSectionTitle>8.5. Empresas do Grupo iFood</SubSectionTitle>
            <p>
              Nós podemos compartilhar os dados que coletamos com as empresas do Grupo Econômico ao
              qual pertence o iFood (“<strong>Grupo iFood</strong>”).
            </p>
            <p>
              As empresas do Grupo do iFood seguem o{' '}
              <strong>mesmo padrão de proteção de dados pessoais </strong>
              descrito nesta Declaração de Privacidade do iFood e obedecem às mesmas finalidades
              descritas neste documento. Ao compartilharmos os seus dados com essas empresas, as
              mesmas <strong>garantias e cuidados que o iFood possui com os seus dados</strong>{' '}
              serão replicados pelas empresas do Grupo do iFood.
            </p>
            <p>
              O compartilhamento de dados com empresas do Grupo do iFood terá como finalidades:{' '}
            </p>
          </div>
        </div>
      </div>
      <div className="data-shared__numbers">
        <div className="container">
          <div className="numbers__list">
            <NumberItem number={1}>
              O <strong>desenvolvimento de novos produtos, funcionalidades e serviços</strong>, bem
              como sua melhoria e aperfeiçoamento
            </NumberItem>
            <NumberItem number={2}>
              A <strong>oferta de produtos e serviços</strong> que melhor atendam aos seus
              interesses
            </NumberItem>
            <NumberItem number={3}>
              Geração de <strong>dados estatísticos e agregados</strong> acerca do uso de nossos
              produtos e serviços e perfis dos usuários
            </NumberItem>
            <NumberItem number={4}>
              <strong>Marketing</strong>, prospecção, <strong>pesquisas de mercado</strong>, de
              opinião e promoção de produtos e serviços
            </NumberItem>
            <NumberItem number={5}>
              <strong>Investigações</strong> e medidas de prevenção e{' '}
              <strong>combate a ilícitos e fraudes</strong>
            </NumberItem>
          </div>
          <div className="columns">
            <div className="column">
              <SubSectionTitle>
                8.6. Em alteração de controle
                <br /> societário do iFood{' '}
              </SubSectionTitle>
              <p>
                No caso de operações societárias, como reestruturação, fusão ou venda de ativos do
                iFood, do grupo econômico ou de parte dele, seus dados poderão ser transferidos,
                desde que respeitados os termos da presente Declaração.
              </p>
            </div>
            <div className="column">
              <SubSectionTitle>
                8.7. Empresas de <br />
                serviços de análise
              </SubSectionTitle>
              <p>
                Para entregarmos publicidade e promoções <strong>adequadas para você</strong>, bem
                como <strong>aperfeiçoar nossos serviços</strong>, nós também podemos compartilhar
                os seus dados anonimizados com empresas especializadas em marketing e análise de
                dados digitais que ofereçam nível de
                <strong>proteção de dados compatível</strong> com esta Declaração de Privacidade.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="data-shared__public-authorities">
        <div className="container">
          <div className="columns is-vcentered">
            <div className="column is-half">
              <SubSectionTitle>8.8. Autoridades públicas</SubSectionTitle>
              <p>O iFood também poderá compartilhar suas informações com:</p>
              <Authorities className="authorities__illustration" />
              <p>
                Dentro e fora do país em que você reside, caso seja requerido pela legislação
                aplicável, por decisão judicial e por requisição de autoridades, ou se necessário
                para responder a processos judiciais ou para participar em eventuais litígios ou
                disputas de qualquer natureza.
              </p>
              <p>
                Nessas situações,{' '}
                <strong>
                  o iFood tem o dever de cooperar com as autoridades competentes na medida do
                  estipulado por lei.
                </strong>
              </p>
              <p>
                Além disso, o iFood reserva a si a prerrogativa de compartilhar informações sobre
                seus usuários a terceiros quando houver motivos suficientes para considerar que a
                atividade de um usuário{' '}
                <strong>é suspeita, ilegal ou prejudicial ao iFood ou a terceiros.</strong>
              </p>
            </div>
            <div className="column is-half">
              <LawAnalysis className="illustration__law-analysis" />
              <InfoBlock>
                <p>
                  Essa prerrogativa será utilizada pelo iFood quando considerar apropriado ou
                  necessário para manter a <strong>integridade e a segurança do seu serviço</strong>
                  , para o cumprimento dos seus Termos e Condições de Uso, para o exercício regular
                  de seus direitos e com o intuito de cooperar com a execução e o cumprimento da
                  lei, independentemente de existir ou não uma ordem judicial ou administrativa para
                  tanto.
                </p>
              </InfoBlock>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default HowDataIsSharedCouriers
