import { getUniqueId } from 'utils/helpers'

// eslint-disable-next-line
export const data = {
  title: ' 1. A quem esta Declaração se aplica?',
  description:
    'Esta <strong>Declaração de Privacidade,</strong> também chamada de “Declaração”, <strong>se aplica em:</strong>',
  audiences: [
    {
      uid: getUniqueId(),
      content: 'Entregadores e Entregadoras',
    },
    {
      uid: getUniqueId(),
      content: 'Operadores Logísticos',
    },
    {
      uid: getUniqueId(),
      content:
        'que se cadastrarem em nosso aplicativo “iFood para Entregadores”, por livre e espontânea vontade, para desempenhar as Atividades de Entrega de acordo com as definições dos <a href="https://entregador.ifood.com.br/destaque-home/privacidade/" target="_blank" rel="noreferrer noopener">Termos e condições de uso iFood para Entregadores. </a>',
    },
  ],
  definitions: [
    {
      uid: getUniqueId(),
      content:
        'O aplicativo “iFood para Entregadores”, disponível somente para Android, será referido aqui como Plataforma.',
    },
  ],
}
