import React from 'react'
import ImageRight from 'assets/svg/introduction-privacy-couriers.svg'
import ImageDotted from 'assets/svg/introduction-privacy-couriers-dotted.svg'
import ImageDottedMobile from 'assets/svg/illustration-dotted-mobile.svg'
import IllustrationHelmet from 'assets/svg/illustration-helmet.svg'
import useWindowSize from 'hooks/useWindowSize'
import './styles.scss'
import InfoBlock from '../../components/InfoBlock'

const IntroductionSectionCouriers = () => {
  const { isMobileDevice } = useWindowSize()
  return (
    <section className="introduction-section-couriers">
      <div className="container">
        <div className="columns">
          <div className="column introduction-section-couriers__container-left">
            <h1 className="text-primary text-7xl">Seus dados são seus.</h1>
            <div>
              <p className="text-lg">
                <strong>Os seus dados são seus.</strong> A proteção dos seus dados é muito
                importante para o iFood. Respeitamos a sua privacidade em nossa missão de
                proporcionar um serviço cada vez melhor.
              </p>
              <p className="text-lg">
                Nesta <strong>Declaração de Privacidade,</strong> você vai saber como obtemos,
                armazenamos, utilizamos, compartilhamos e<strong> protegemos</strong> os seus dados.
              </p>
              <p className="text-lg">
                <strong>Recomendamos que leia as nossas práticas como um todo, </strong>
                mas separamos alguns pontos importantes para que você entenda como seus dados são
                tratados pelo iFood:
              </p>
            </div>
          </div>
          <div className="column introduction-section-couriers__container-right">
            <ImageRight />
          </div>
        </div>
        <div className="column introduction-section-couriers__container-bottom">
          {isMobileDevice ? <ImageDottedMobile /> : <ImageDotted />}
        </div>
        <div className="column introduction-section-couriers__container-infoBlock">
          <InfoBlock className="introduction-block-text">
            <IllustrationHelmet />
            <p className="text-xl">
              Além disso, também aconselhamos que você conheça melhor o que é esperado da nossa
              parceria pela leitura dos{' '}
              <a href="https://entregador.ifood.com.br/termos/termosdeuso/">
                Termos e condições de uso iFood para Entregadores
              </a>
              .
            </p>
          </InfoBlock>
        </div>
      </div>
    </section>
  )
}

export default IntroductionSectionCouriers
