import React from 'react'
import Illustration from 'assets/svg/protecao-dados.svg'
import SectionTitle from '../../components/SectionTitle'

import './styles.scss'

const ProtectingYourDataCouriers = () => (
  <section className="privacy-couriers__protecting-data bg-grey-bg" id="capitulo-6">
    <div className="container">
      <div className="columns is-vcentered">
        <div className="column privacy-couriers__protecting-data--left">
          <SectionTitle>6. Como protegemos seus dados</SectionTitle>
          <p>
            O iFood adota medidas de segurança, técnicas e administrativas para
            <strong>
              {' '}
              proteger os dados pessoais de acessos não autorizados e de situações acidentais ou
              ilícitas
            </strong>{' '}
            de destruição, perda, alteração, comunicação ou{' '}
            <strong>qualquer forma de tratamento inadequado ou ilícito.</strong>
          </p>
          <p>
            Utilizamos os princípios estipulados por lei,{' '}
            <strong>respeitando a sua privacidade e protegendo seus dados</strong> em nossos
            processos internos como um todo.
          </p>
          <p>
            Só tratamos os seus dados mediante <strong>alto grau de segurança, </strong>
            implementando as <strong>melhores práticas</strong> em uso na indústria para a{' '}
            <strong>proteção de dados, </strong>
            tais como técnicas de criptografia, monitoramento e testes de segurança periódicos.
          </p>
        </div>
        <div className="column privacy-couriers__protecting-data--right">
          <Illustration />
        </div>
      </div>
    </div>
  </section>
)

export default ProtectingYourDataCouriers
