import React from 'react'

import useWindowSize from 'hooks/useWindowSize'

import UserRightText from 'assets/svg/direitos-usuario.svg'
import UserRightTextMobile from 'assets/svg/direitos-usuario-mobile.svg'
import CallCenter from 'assets/svg/callcenter.svg'
import SectionTitle from '../../components/SectionTitle'

import './styles.scss'

const UserRightsCouriers = () => {
  const { isMobileDevice } = useWindowSize()
  return (
    <section className="privacy-couriers__user-rights" id="capitulo-11">
      <div className="container">
        <div className="columns">
          <div className="column is-half">
            <SectionTitle>11. Quais são os seus direitos?</SectionTitle>
            <p className="text-xl font-normal">
              Citamos, abaixo, os direitos de titular que você pode nos solicitar:
            </p>
          </div>
        </div>
        {isMobileDevice ? <UserRightTextMobile /> : <UserRightText />}
        <CallCenter />
      </div>
    </section>
  )
}

export default UserRightsCouriers
