import React from 'react'
import Illustration from 'assets/svg/couriers-privacy-chapter-4-person-with-cellphone.svg'
import ExclamationMark from 'assets/svg/illustration-exclamacao.svg'
import CourierOnMotorcycle from 'assets/svg/couriers-privacy-chapter-4-courier-on-motorcycle.svg'
import HandWithLens from 'assets/svg/couriers-privacy-chapter-4-lens-rectangle.svg'
import InfoDots from 'assets/svg/couriers-privacy-chapter-4-info-dots.svg'
import MobileInfoBoxes from 'assets/svg/couriers-privacy-chapter-4-mobile-info-boxes.svg'
import IllustrationData from 'assets/svg/esquema.svg'
import IllustrationSubChapterC from 'assets/svg/illustra-4-2-c.svg'
import IllustrationChapterFour from 'assets/svg/illustration-chapter-4-3.svg'
import IllustrationChapterFourMobile from 'assets/svg/circles.svg'
import useWindowSize from 'hooks/useWindowSize'
import SectionTitle from '../../components/SectionTitle'
import SubSectionTitle from '../../components/SubSectionTitle'
import InfoBlock from '../../components/InfoBlock'
import CardText from '../../components/CardText'

import './styles.scss'

const WhatDataIsCollectedCouriers = () => {
  const { isMobileDevice } = useWindowSize()
  return (
    <section id="capitulo-4" className="what-data-collected-couriers">
      <div className="what-data-collected-couriers__subsection-grey">
        <div className="container">
          <div className="columns subsection-grey-chapter-four">
            <div className="column column-left">
              <SectionTitle>4. Quais dados são coletados?</SectionTitle>
              <p className="text-xl text-grey-2 font-normal">
                Ao se cadastrar no{' '}
                <span className="text-primary font-bold">iFood para Entregadores</span>, nós
                poderemos coletar informações importantes sobre você.
              </p>
              <p className="text-xl text-grey-2 font-normal">
                Abaixo, detalhamos quais são essas informações.
              </p>
            </div>
            <div className="column column-right person-making-a-selfie">
              <Illustration />
            </div>
          </div>
          <div className="subsection-grey-chapter-four-one">
            <div className="columns subsection-grey-chapter-four-one__a">
              <div className="column">
                <SubSectionTitle>4.1 Dados que você nos fornece</SubSectionTitle>
                <p className="text-xl text-black font-bold">a) Dados cadastrais</p>
                <p className="text-xl font-normal text-grey-2">
                  No cadastro, <strong>você nos fornece as seguintes informações</strong> sobre
                  você:
                </p>
                <div className="is-flex is-justify-content-center">
                  {isMobileDevice ? <MobileInfoBoxes /> : <InfoDots />}
                </div>
              </div>
            </div>
            <p className="text-xl text-black section-title font-bold">b) Dados de pagamento</p>
            <div className="columns subsection-grey-chapter-four-one__b">
              <div className="column column-left">
                <p className="text-grey-2">
                  Ao realizar as atividades de entrega por meio da Plataforma, você receberá o
                  repasse do valor correspondente à rota conforme estipulado nos{' '}
                  <a
                    href="https://entregador.ifood.com.br/destaque-home/privacidade/"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="text-primary font-bold"
                  >
                    Termos e Condições de uso iFood para Entregadores.
                  </a>
                </p>
                <p className="text-grey-2">
                  E, para que façamos o repasse das entregas concluídas, é necessário que você
                  informe os seus dados bancários de uma conta corrente ou poupança, tais como conta
                  e agência, seja diretamente ao iFood ou via Operadores Logísticos.
                </p>
              </div>
              <div className="column column-right is-flex is-align-items-flex-start">
                <InfoBlock className="text-block-chapter-four-one-b">
                  <p className="text-white">
                    Lembre-se de <strong>sempre manter os seus dados bancários atualizados</strong>{' '}
                    na Plataforma.
                  </p>
                </InfoBlock>
              </div>
            </div>
            <div className="columns subsection-grey-chapter-four-one__c">
              {!isMobileDevice && (
                <div className="column is-flex is-justify-content-center">
                  <CourierOnMotorcycle />
                </div>
              )}
              <div className="column">
                <p className="text-xl font-bold text-black">c) Dados de locallização</p>
                <p className="text-grey-2">
                  No momento do cadastro, você nos informará a sua cidade quando definir a praça em que irá atuar pela Plataforma. Também analisamos sua localização para validar a sua praça de atuação, dentro dos limites permitidos por lei.
                </p>
                <p className="text-grey-2">
                  Em alguns casos, nós compartilhamos o seu trajeto para clientes acompanharem as entregas.
                </p>
                <p className="text-grey-2">
                  Essa informação será coletada do seu celular via GPS e redes méoveis (torres de celular, Wi-Fi e outras modalidades de localização) por meio da permissão que você fornecer ao seu dispositivo e sempre que estiver disponível na Plataforma, <strong>sendo essa uma condição necessária para utilizá-la.</strong>
                </p>
              </div>
              {isMobileDevice && (
                <div className="column column-left">
                  <CourierOnMotorcycle />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="what-data-collected-couriers__subsection-white">
        <div className="container">
          <div className="column subsection-white-chapter-four-two-a">
            <SubSectionTitle>4.2. Dados gerados durante a utilização dos serviços</SubSectionTitle>
            <p className="text-xl font-bold text-black subtitle">a) Dados dos seus dispositivos</p>
            <p className="text-grey-2">
              <strong>Nós podemos,</strong> automaticamente, coletar informações sobre os
              dispositivos a partir dos quais você acessa o iFood para Entregadores, como:
            </p>
            {isMobileDevice ? <IllustrationChapterFourMobile /> : <IllustrationData />}
          </div>
          <div className="columns subsection-white-chapter-four-two-b">
            <div className="column">
              <p className="text-xl font-bold text-black">b) Dados de localização</p>
              <p className="text-grey-2">
                Analisamos sua localização para liberar seu cadastro na Plataforma e validar a sua praça de atuação, dentro dos limites permitidos por lei.
              </p>
              <p className="text-grey-2">
                Em alguns casos, nós compartilhamos o seu trajeto para clientes acompanharem as entregas.
              </p>
              <p className="text-grey-2">
                Essa informação será coletada do seu celular via GPS e redes móveis (torres de celular, Wi-Fi e outras modalidades de localização) por meio da permissão que você fornecer ao seu dispositivo e sempre que estiver disponível na Plataforma, sendo essa uma condição necessária para utilizá-la.
              </p>
            </div>
          </div>
          <div className="columns subsection-white-chapter-four-two-b">
            <div className="column">
              <p className="text-xl font-bold text-black">c) Dados de comunicação</p>
              <p className="text-grey-2">
                Intermediamos a comunicação entre Clientes Finais, Entregadores, Entregadoras e
                Estabelecimentos Parceiros feita por meio do chat ou de telefone anonimizado na
                nossa Plataforma.
              </p>
              <InfoBlock>
                <ExclamationMark />
                <p>
                  Para isso, o iFood recebe dados relativos às chamadas, textos ou outras
                  comunicações, incluindo a data e hora das comunicações e o seu conteúdo.
                </p>
              </InfoBlock>
            </div>
            <div className="column">
              <IllustrationSubChapterC className="illutration-chapter-c" />
            </div>
          </div>
          <div className="columns subsection-white-chapter-four-two-c">
            <div className="column">
              <p className="text-xl font-bold text-black subtitle">
                d) Informações de outras fontes
              </p>
              <p className="text-grey-2">
                Os Clientes Finais nos fornecem informações que podem incluir dados de Entregadores
                e Entregadoras, como:
              </p>
              <div className="subsection-white-chapter-four-two-a__cards-list">
                <CardText text="avaliações" />
                <CardText text="comentários" />
                <CardText text="reclamações" />
              </div>
            </div>
          </div>
          <div className="columns subsection-white-chapter-four-two-c-banner">
            <div className="column column-left is-flex is-align-items-center is-justify-content-center">
              <HandWithLens />
            </div>
            <div className="column column-right is-flex is-align-items-center is-justify-content-center">
              <p className="text-grey-2">
                Para fins de segurança e prevenção a fraudes,{' '}
                <span className="text-black font-bold">
                  podemos realizar consultas de dados em bases públicas e receber dados
                  compartilhados por fornecedores
                </span>
                , que incluem confirmação de sua identidade e{' '}
                <span className="text-black font-bold">informações de antecedentes.</span>
              </p>
            </div>
          </div>
          <div className="columns subsection-white-chapter-four-three">
            <div className="column column-left">
              <SubSectionTitle>4.3 Dados não-pessoais</SubSectionTitle>
              <p className="text-grey-2">
                Nós também podemos obter informações não-pessoais, ou seja,{' '}
                <strong>
                  dados que não permitem a associação direta com qualquer pessoa especificamente
                </strong>
                . É um exemplo de dado não-pessoal o agrupamento de pedidos por região.
              </p>
              <InfoBlock className="text-block-chapter-four-three">
                <p className="text-white">
                  Outras informações que podemos obter são sobre as atividades de Entregadores e
                  Entregadoras em nossa Plataforma. Esse material é usado para nos ajudar a entender
                  o que funciona melhor na Plataforma. Dados agregados são considerados informações
                  não-pessoais para fins desta Declaração.
                </p>
              </InfoBlock>
              <p className="text-grey-2">
                Se combinarmos informações não-pessoais com informações pessoais,{' '}
                <strong>
                  a informação combinada será considerada e tratada como pessoal enquanto permanecer
                  combinada.
                </strong>
              </p>
            </div>
            <div className="column column-right is-flex is-align-items-flex-start is-justify-content-center">
              <IllustrationChapterFour />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default WhatDataIsCollectedCouriers
