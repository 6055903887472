import background from 'assets/images/privacy-couriers/banner-privacy-couriers.png'

import CookiesInfoCouriers from './CookiesInfoCouriers'
import DataTransferToOtherCountriesCouriers from './DataTransferToOtherCountriesCouriers'
import ExerciseUserRightsCouriers from './ExerciseUserRightsCouriers'
import HowDataIsSharedCouriers from './HowDataIsSharedCouriers'
import HowDataIsStoredCouriers from './HowDataIsStoredCouriers'
import NotifyingDeclarationChangesCouriers from './NotifyingDeclarationChangesCouriers'
import ProcessingPersonalDataCouriers from './ProcessingPersonalDataCouriers'
import ProtectingYourDataCouriers from './ProtectingYourDataCouriers'
import UserRightsCouriers from './UserRightsCouriers'
import WhatDataIsCollectedCouriers from './WhatDataIsCollectedCouriers'
import WhoControlsTreatmentCouriers from './WhoControlsTreatmentCouriers'
import WhoThisDeclarationApplyCouriers from './WhoThisDeclarationApplyCouriers'
import WhyCollectYourDataCouriers from './WhyCollectYourDataCouriers'

// eslint-disable-next-line
export const data = {
  listButtonsLeft: [
    {
      textButton: '1. A quem esta Declaração se aplica?',
      href: 'capitulo-1',
      content: WhoThisDeclarationApplyCouriers,
    },
    {
      textButton: '2. O que é o tratamento de dados pessoais?',
      href: 'capitulo-2',
    },
    {
      textButton: '3. Quem controla o tratamento?',
      href: 'capitulo-3',
    },
    {
      textButton: '4. Quais dados são coletados?',
      href: 'capitulo-4',
    },
    {
      textButton: '5. Por que coletamos os seus dados?',
      href: 'capitulo-5',
    },
    {
      textButton: '6. Como protegemos os seus dados?',
      href: 'capitulo-6',
    },
    {
      textButton: '7. Como os dados são armazenados?',
      href: 'capitulo-7',
    },
  ],
  listButtonsRight: [
    {
      textButton: '8. Como os dados são compartilhados?',
      href: 'capitulo-8',
    },
    {
      textButton: '9. Como utilizamos cookies e outras tecnologias?',
      href: 'capitulo-9',
    },
    {
      textButton: '10. O iFood transfere os dados para outros países?',
      href: 'capitulo-10',
    },
    {
      textButton: '11. Quais são os seus direitos?',
      href: 'capitulo-11',
    },
    {
      textButton: '12. Como exercer os seus direitos?',
      href: 'capitulo-12',
    },
    {
      textButton: '13. Como notificamos as mudanças feitas nesta Declaração?',
      href: 'capitulo-13',
    },
  ],
  listMobileContent: [
    {
      textButton: '1. A quem esta Declaração se aplica?',
      content: WhoThisDeclarationApplyCouriers,
    },
    {
      textButton: '2. O que é o tratamento de dados pessoais?',
      content: ProcessingPersonalDataCouriers,
    },
    {
      textButton: '3. Quem controla o tratamento?',
      content: WhoControlsTreatmentCouriers,
    },
    {
      textButton: '4. Quais dados são coletados?',
      content: WhatDataIsCollectedCouriers,
    },
    {
      textButton: '5. Por que coletamos os seus dados?',
      content: WhyCollectYourDataCouriers,
    },
    {
      textButton: '6. Como protegemos os seus dados?',
      content: ProtectingYourDataCouriers,
    },
    {
      textButton: '7. Como os dados são armazenados?',
      content: HowDataIsStoredCouriers,
    },
    {
      textButton: '8. Como os dados são compartilhados?',
      content: HowDataIsSharedCouriers,
    },
    {
      textButton: '9. Como utilizamos cookies e outras tecnologias?',
      content: CookiesInfoCouriers,
    },
    {
      textButton: '10. O iFood transfere os dados para outros países?',
      content: DataTransferToOtherCountriesCouriers,
    },
    {
      textButton: '11. Quais são os seus direitos?',
      content: UserRightsCouriers,
    },
    {
      textButton: '12. Como exercer os seus direitos?',
      content: ExerciseUserRightsCouriers,
    },
    {
      textButton: '13. Como notificamos as mudanças feitas nesta Declaração?',
      content: NotifyingDeclarationChangesCouriers,
    },
  ],
  dataAtualization: '13 de julho de 2023',
  lastVersions: {
    label: 'Acesse aqui',
    options: [
      {
        label: { title: 'Versão 3', description: '(18/08/2022 até 12/07/2023)' },
        value: '/abrindo-a-cozinha/privacidade-entregador-versao3/',
      },
      {
        label: { title: 'Versão 2', description: '(23/04/2021 até 17/08/2022)' },
        value: '/abrindo-a-cozinha/privacidade-entregador-versao2/',
      },
      {
        label: { title: 'Versão 1', description: '(10/12/2019 até 22/04/2021)' },
        value: '/abrindo-a-cozinha/privacidade-entregador-versao1/',
      },
    ],
  },
  timeToRead: '34 minutos',
  backgroundImage: background,
  typeUser: 'Entregadores',
}
